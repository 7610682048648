<template>
<div class="widget">
    <h6>Assigned To Me</h6>
    <div class="widger_body">
      <div class="chartdiv" id="taskgraph"></div>

             <ul class="legendlist">
            <li class="filed">
                <p>Completed</p>
            </li>
            <li class="nextdate">
                <p>Pending</p>
            </li>
            <li class="overdue">
                <p>Overdue</p>
            </li>
        </ul>
    </div>
    
</div>
</template>

<script>
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4charts from "@amcharts/amcharts4/charts";
 // import am4themes_animated from "@amcharts/amcharts4/themes/animated";
  export default {
    components: {
        
    },
    data() {
      return {
         
 
      }
    },
    mounted() {
      this.initChart();
    },

     methods: {
      initChart() {
        // Create chart instance
var chart = am4core.create("taskgraph", am4charts.PieChart);

// Add data
chart.data = [{
  "status": "Completed ",
  "count": 30.3,
}, {
  "status": "Pending",
  "count": 30.3
},  {
  "status": "Over Due",
  "count": 30.3
}];

// Add and configure Series
var pieSeries = chart.series.push(new am4charts.PieSeries());

            var colorSet = new am4core.ColorSet();
            colorSet.list = ["#009D9A", "#FFA726", "#BC5090"].map(function (color) {
                return new am4core.color(color);
            });


pieSeries.dataFields.value = "count";
pieSeries.dataFields.category = "status";
            pieSeries.colors = colorSet;

// Let's cut a hole in our Pie chart the size of 40% the radius
chart.innerRadius = am4core.percent(50);

// Put a thick white border around each Slice
pieSeries.slices.template.stroke = am4core.color("#4a2abb");
pieSeries.slices.template.strokeWidth = 0;
pieSeries.slices.template.strokeOpacity = 1;

// Disable ticks and labels
pieSeries.labels.template.disabled = true;
pieSeries.ticks.template.disabled = true;


      }
    }  
 
  }
</script>
<template>
<div class="widget">
    <h6>Time Tracker</h6>
    <div class="widger_body">
      <div class="tasks-section widget_filters">
        <div class="tasks-header">
            <div class="task-filters-left">
                    <ul>
                        <li v-on:click="isActive='All'" v-bind:class="{ active: isActive=='All' }">All</li>
                        <li v-on:click="isActive='High'" v-bind:class="{ active: isActive=='High' }" >High</li>
                        <li v-on:click="isActive='Medium'" v-bind:class="{ active: isActive=='Medium' }" >Medium</li>
                        <li v-on:click="isActive='Low'" v-bind:class="{ active: isActive=='Low' }" >Low</li>
                    </ul>
            </div>
            <div class="task-filters-right">
                <ul>
                    <li>
                        <label>ASSIGNED TYPE</label>
                        <multiselect
                            v-model="assignedSelected"
                            :show-labels="false" 
                            :options="assignedOptions">
                        </multiselect>
                    </li> 
                    <li>
                        <label>TIME RANGE</label>  
                        <div class="date_range">
                            <dateRange></dateRange>                                 
                        </div>
                    </li>
                </ul>
            </div>
        </div>
      </div> 
        <div class="assigned_tracker">
          <div class="assigned_pie">
            <div class="chartdiv" id="assignChart"></div>
                     <ul class="legendlist">
            <li class="filed">
                <p>Completed</p>
            </li>
            <li class="nextdate">
                <p>Pending</p>
            </li>
            <li class="overdue">
                <p>Overdue</p>
            </li>
        </ul>
          </div>
          <div class="assigned_chart">
            <div class="graph_cnt">
          <ul>
            <li> 
              <div class="graph_elements">
                <p style="height:25%; background:#009D9A"></p>
                <p style="height:50%; background:#FFA726"></p>
                <p style="height:25%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-1.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-1.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>
              
              <div class="graph_elements">
                <p style="height:30%; background:#009D9A"></p>
                <p style="height:30%; background:#FFA726"></p>
                <p style="height:40%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-2.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-2.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>              
              <div class="graph_elements">
                <p style="height:25%; background:#009D9A"></p>
                <p style="height:30%; background:#FFA726"></p>
                <p style="height:45%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-3.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-3.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>               
              <div class="graph_elements">
                <p style="height:40%; background:#009D9A"></p>
                <p style="height:20%; background:#FFA726"></p>
                <p style="height:40%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-4.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-4.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
             <li> 
              <div class="graph_elements">
                <p style="height:25%; background:#009D9A"></p>
                <p style="height:50%; background:#FFA726"></p>
                <p style="height:25%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-1.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-1.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>
              
              <div class="graph_elements">
                <p style="height:30%; background:#009D9A"></p>
                <p style="height:30%; background:#FFA726"></p>
                <p style="height:40%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-2.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-2.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>              
              <div class="graph_elements">
                <p style="height:25%; background:#009D9A"></p>
                <p style="height:30%; background:#FFA726"></p>
                <p style="height:45%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-3.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-3.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>               
              <div class="graph_elements">
                <p style="height:40%; background:#009D9A"></p>
                <p style="height:20%; background:#FFA726"></p>
                <p style="height:40%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-4.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-4.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li> 
              <div class="graph_elements">
                <p style="height:25%; background:#009D9A"></p>
                <p style="height:50%; background:#FFA726"></p>
                <p style="height:25%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-1.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-1.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>
              
              <div class="graph_elements">
                <p style="height:30%; background:#009D9A"></p>
                <p style="height:30%; background:#FFA726"></p>
                <p style="height:40%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-2.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-2.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>              
              <div class="graph_elements">
                <p style="height:25%; background:#009D9A"></p>
                <p style="height:30%; background:#FFA726"></p>
                <p style="height:45%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-3.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-3.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>               
              <div class="graph_elements">
                <p style="height:40%; background:#009D9A"></p>
                <p style="height:20%; background:#FFA726"></p>
                <p style="height:40%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-4.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-4.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li> 
              <div class="graph_elements">
                <p style="height:25%; background:#009D9A"></p>
                <p style="height:50%; background:#FFA726"></p>
                <p style="height:25%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-1.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-1.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>
              
              <div class="graph_elements">
                <p style="height:30%; background:#009D9A"></p>
                <p style="height:30%; background:#FFA726"></p>
                <p style="height:40%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-2.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-2.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>              
              <div class="graph_elements">
                <p style="height:25%; background:#009D9A"></p>
                <p style="height:30%; background:#FFA726"></p>
                <p style="height:45%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-3.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-3.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
            <li>               
              <div class="graph_elements">
                <p style="height:40%; background:#009D9A"></p>
                <p style="height:20%; background:#FFA726"></p>
                <p style="height:40%; background:#BC5090"></p>
                <div class="graph_elements_popper">
                  <label>Total Task  26</label>
                  <ul>
                    <li class="filed"><p>Completed <span>6</span></p></li>
                    <li class="nextdate"><p>Pending <span>10</span></p></li>
                    <li class="overdue"><p>Over Due <span>10</span></p></li>
                  </ul>
                </div>
              </div>
              <div class="indv_user">
                <figure><img src="@/assets/images/user-4.png"></figure>
                <div class="user-details">
                    <img src="@/assets/images/user-4.png">
                    <span>Prabhas TV</span>
                </div>  
              </div>
            </li>
          </ul>
            </div>
            <p class="subtitle">Assigned task by me</p>
          </div>          
        </div>            
    </div>
    
</div>
</template>

<script>
  import Multiselect from "vue-multiselect-inv";
  import dateRange from '@/components/dateRange.vue'
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4charts from "@amcharts/amcharts4/charts";
  export default {
    components: {
        Multiselect,
        dateRange
    },
    data() {
      return {
        isActive: 'All',
        assignedSelected: 'All',
        subCategorySelected: 'All',
        TimeSelected: 'All',
        assignedOptions: ['All ', 'Assigned by me'], 
        TimeOptions: ['All ', 'Time 1', 'Time 2', 'Time 3'],
      }
    },
    mounted() {
      this.assignChart();
    },

     methods: {
  
      assignChart() {
        // Create chart instance
        var chart = am4core.create("assignChart", am4charts.PieChart);

     
// Add data
chart.data = [{
  "status": "Completed ",
  "count": 33,
}, {
  "status": "Pending",
  "count": 42
},  {
  "status": "Over Due",
  "count": 25
}];

// Add and configure Series
var pieSeries = chart.series.push(new am4charts.PieSeries());

            var colorSet = new am4core.ColorSet();
            colorSet.list = ["#009D9A", "#FFA726", "#BC5090"].map(function (color) {
                return new am4core.color(color);
            });


pieSeries.dataFields.value = "count";
pieSeries.dataFields.category = "status";
            pieSeries.colors = colorSet;

// Let's cut a hole in our Pie chart the size of 40% the radius
chart.innerRadius = am4core.percent(50);

// Put a thick white border around each Slice
pieSeries.slices.template.stroke = am4core.color("#4a2abb");
pieSeries.slices.template.strokeWidth = 0;
pieSeries.slices.template.strokeOpacity = 1;

// Disable ticks and labels
pieSeries.labels.template.disabled = true;
pieSeries.ticks.template.disabled = true;

      }
    }
  }
</script>
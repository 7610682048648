<template>
<div>
     <div class="page-header justify-content-start marb0">
            <lable class="page-title ">Dashboard</lable>
            <!-- <ul class="breadcrumbs">
                <li>Raj Sinha > Pending Task (04)</li>
            </ul> -->
        </div>
        <div class="task-filters">
            <ul>
                <li v-on:click="isCurrent='Alerts'" v-bind:class="{ active: isCurrent=='Alerts' }" ><p>Alerts</p></li>
                <li v-on:click="isCurrent='Schedule'" v-bind:class="{ active: isCurrent=='Schedule' }"><p>Schedule</p></li>
                <li v-on:click="isCurrent='ToDo'" v-bind:class="{ active: isCurrent=='ToDo' }"><p>To Do</p></li>
                <li v-on:click="isCurrent='Performance'" v-bind:class="{ active: isCurrent=='Performance' }"><p>Task Performance</p></li>
            </ul>
        </div>
        <section class="tasks-section ">
            

            <div class="dashborad_main_content task-wrap">
                <div class="dashborad_cnt"  v-bind:class="{ 'open': isCurrent=='Alerts' }" >
                    <div class="tasks-header">
                        <div class="task-filters-left">
                            <ul>
                                <li v-on:click="isActive='All'" v-bind:class="{ active: isActive=='All' }">All</li>
                                <li v-on:click="isActive='High'" v-bind:class="{ active: isActive=='High' }" >Online</li>
                                <li v-on:click="isActive='Medium'" v-bind:class="{ active: isActive=='Medium' }" >Offline</li> 
                            </ul>
                        </div>
                        <div class="task-filters-right">
                            <ul>
                                <li>
                                    <label>MEETING</label>
                                    <multiselect
                                        v-model="categorySelected"
                                        :show-labels="false" 
                                        :options="categoryOptions">
                                    </multiselect>
                                </li>
                                <li>
                                    <label>LOCATION</label> 
                                    <multiselect
                                        v-model="subCategorySelected" 
                                        :show-labels="false" 
                                        :options="subCategoryOptions">
                                    </multiselect>
                                </li>
                                <li>
                                    <label>TIME RANGE</label>  
                                    <div class="date_range">
                                        <dateRange></dateRange>                                 
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="task-list high">
                        <ul>
                            <li class="category">
                                <div class="task-item">
                                    <label>CATEGORY</label>
                                    <div class="task-content"><span>administration</span></div>
                                </div>
                                <div class="task-item">
                                    <label>DATE/TIME</label>
                                    <div class="task-content date"><p>21st Nov '20 </p><p>11:00 am - 1:00 pm</p></div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Priority</label>
                                    <div class="task-content">
                                        <p>High</p>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>Estimated time</label>
                                    <div class="task-content">
                                        <p>5 Mins</p>
                                    </div>
                                </div>
                            </li>
                            <li class="subject">
                                <div class="task-item">
                                    <label>SUBJECT</label>
                                    <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                                </div>
                                <div class="task-item">
                                    <label>DESCRIPTION</label>
                                    <div class="task-content">
                                        <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                                    </div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Assigned by</label>
                                    <div class="task-content">
                                        <div class="profile-sec">
                                            <figure><img src="@/assets/images/user.png"></figure>
                                            <figcaption>Meenal Shah</figcaption>
                                        </div>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>ATTACHMENT</label>
                                    <div class="task-content">
                                        <div class="attachments">
                                            <small>4</small>
                                        </div>
                                    </div>
                                </div>
                            </li>  
                            <li class="task-actions alert_actions">
                                <div class="task-item">
                                    <label>Action</label>
                                    <div class="task-content">
                                        <p>START</p>
                                        <p>Archive</p>
                                        <p>View Details</p>
                                    </div> 
                                </div> 
                            </li>
                        </ul>
                    </div>
                    <div class="task-list low">
                        <ul>
                            <li class="category">
                                <div class="task-item">
                                    <label>CATEGORY</label>
                                    <div class="task-content"><span>Publication &amp; Media</span></div>
                                </div>
                                <div class="task-item">
                                    <label>DATE/TIME</label>
                                    <div class="task-content date"><p>21st Nov '20 </p><p>11:00 am - 1:00 pm</p></div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Priority</label>
                                    <div class="task-content">
                                        <p>Low</p>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>Estimated time</label>
                                    <div class="task-content">
                                        <p>5 Mins</p>
                                    </div>
                                </div>
                            </li>
                            <li class="subject">
                                <div class="task-item">
                                    <label>SUBJECT</label>
                                    <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                                </div>
                                <div class="task-item">
                                    <label>DESCRIPTION</label>
                                    <div class="task-content">
                                        <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                                    </div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Assigned by</label>
                                    <div class="task-content">
                                        <div class="profile-sec">
                                            <figure><img src=" "></figure>
                                            <figcaption>Meenal Shah</figcaption>
                                        </div>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>ATTACHMENT</label>
                                    <div class="task-content">
                                        <div class="attachments">
                                            <small>4</small>
                                        </div>
                                    </div>
                                </div>
                            </li>  
                            <li class="task-actions alert_actions">
                                <div class="task-item">
                                    <label>Action</label>
                                    <div class="task-content">
                                        <p>START</p>
                                        <p>Archive</p>
                                        <p>View Details</p>
                                    </div> 
                                </div> 
                            </li>
                        </ul>
                    </div>
                    <div class="task-list medium">
                        <ul>
                            <li class="category">
                                <div class="task-item">
                                    <label>CATEGORY</label>
                                    <div class="task-content"><span>administration</span></div>
                                </div>
                                <div class="task-item">
                                    <label>DATE/TIME</label>
                                    <div class="task-content date"><p>21st Nov '20 </p><p>11:00 am - 1:00 pm</p></div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Priority</label>
                                    <div class="task-content">
                                        <p>Medium</p>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>Estimated time</label>
                                    <div class="task-content">
                                        <p>5 Mins</p>
                                    </div>
                                </div>
                            </li>
                            <li class="subject">
                                <div class="task-item">
                                    <label>SUBJECT</label>
                                    <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                                </div>
                                <div class="task-item">
                                    <label>DESCRIPTION</label>
                                    <div class="task-content">
                                        <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                                    </div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Assigned by</label>
                                    <div class="task-content">
                                        <div class="profile-sec">
                                            <figure><img src="@/assets/images/user.png"></figure>
                                            <figcaption>Meenal Shah</figcaption>
                                        </div>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>ATTACHMENT</label>
                                    <div class="task-content">
                                        <div class="attachments">
                                            <small>4</small>
                                        </div>
                                    </div>
                                </div>
                            </li>  
                            <li class="task-actions alert_actions">
                                <div class="task-item">
                                    <label>Action</label>
                                    <div class="task-content">
                                        <p>START</p>
                                        <p>Archive</p>
                                        <p>View Details</p>
                                    </div> 
                                </div> 
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="dashborad_cnt" v-bind:class="{ 'open': isCurrent=='Schedule' }">
                    <div class="tasks-header">
                        <div class="task-filters-left">
                            <ul>
                                <li v-on:click="isActive='All'" v-bind:class="{ active: isActive=='All' }">All</li>
                                <li v-on:click="isActive='High'" v-bind:class="{ active: isActive=='High' }" >Online</li>
                                <li v-on:click="isActive='Medium'" v-bind:class="{ active: isActive=='Medium' }" >Offline</li> 
                            </ul>
                        </div>
                        <div class="task-filters-right">
                            <ul>
                                <li>
                                    <label>MEETING</label>
                                    <multiselect
                                        v-model="categorySelected"
                                        :show-labels="false" 
                                        :options="categoryOptions">
                                    </multiselect>
                                </li>
                                <li>
                                    <label>LOCATION</label> 
                                    <multiselect
                                        v-model="subCategorySelected" 
                                        :show-labels="false" 
                                        :options="subCategoryOptions">
                                    </multiselect>
                                </li>
                                <li>
                                    <label>TIME RANGE</label> 
                                    <div class="date_range">
                                        <dateRange></dateRange>                                 
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="task-list high">
                        <ul>
                            <li class="category">
                                <div class="task-item">
                                    <label>CATEGORY</label>
                                    <div class="task-content"><span>administration</span></div>
                                </div>
                                <div class="task-item">
                                    <label>DATE/TIME</label>
                                    <div class="task-content date">
                                        <p>21st Nov '20 </p>
                                        <p>11:00 am - 1:00 pm</p>
                                    </div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label><br/></label>
                                    <div class="task-content">
                                         
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>Location</label>
                                    <div class="task-content location">
                                        <p>Mumbai</p><br/>
                                        <a>Taj Western<img src="@/assets/images/info.svg"></a>
                                    </div>
                                </div>
                            </li>
                            <li class="subject">
                                <div class="task-item">
                                    <label>SUBJECT</label>
                                    <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                                </div>
                                <div class="task-item">
                                    <label>DESCRIPTION</label>
                                    <div class="task-content">
                                        <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                                    </div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Team Member</label>
                                    <div class="task-content">
                                        <ul class="members_list">
                                            <li><figure><img src="@/assets/images/user.png"></figure></li>
                                            <li><figure><img src=""></figure></li>
                                            <li><figure><img src="@/assets/images/user.png"></figure></li>
                                            <li class="more"><span>+1</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>ATTACHMENT</label>
                                    <div class="task-content">
                                        <div class="attachments">
                                            <small>4</small>
                                        </div>
                                    </div>
                                </div>
                            </li>  
                            <li class="task-actions alert_actions2">
                                <div class="task-item">
                                    <label>Action</label>
                                    <div class="task-content">
                                        <p>START</p>
                                        <p>View Details</p> 
                                    </div> 
                                </div> 
                                <div class="task-item">
                                    <label><br/></label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="task-list low">
                        <ul>
                            <li class="category">
                                <div class="task-item">
                                    <label>CATEGORY</label>
                                    <div class="task-content"><span>administration</span></div>
                                </div>
                                <div class="task-item">
                                    <label>DATE/TIME</label>
                                    <div class="task-content date">
                                        <p>21st Nov '20 </p>
                                        <p>11:00 am - 1:00 pm</p>
                                    </div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label><br/></label>
                                    <div class="task-content">
                                         
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>Location</label>
                                    <div class="task-content location">
                                        <p>Mumbai</p><br/>
                                        <a>Taj Western<img src="@/assets/images/info.svg"></a>
                                    </div>
                                </div>
                            </li>
                            <li class="subject">
                                <div class="task-item">
                                    <label>SUBJECT</label>
                                    <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                                </div>
                                <div class="task-item">
                                    <label>DESCRIPTION</label>
                                    <div class="task-content">
                                        <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                                    </div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Team Member</label>
                                    <div class="task-content">
                                        <ul class="members_list">
                                            <li><figure><img src="@/assets/images/user.png"></figure></li>
                                            <li><figure><img src=""></figure></li>
                                            <li><figure><img src="@/assets/images/user.png"></figure></li>
                                            <li class="more"><span>+1</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>ATTACHMENT</label>
                                    <div class="task-content">
                                        <div class="attachments">
                                            <small>4</small>
                                        </div>
                                    </div>
                                </div>
                            </li>  
                            <li class="task-actions alert_actions2">
                                <div class="task-item">
                                    <label>Action</label>
                                    <div class="task-content">
                                        <p>START</p>
                                        <p>View Details</p> 
                                    </div> 
                                </div> 
                                <div class="task-item">
                                    <label><br/></label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="dashborad_cnt"  v-bind:class="{ 'open': isCurrent=='ToDo' }">
                    <div class="tasks-header">
                        <div class="task-filters-left">
                            <ul>
                                <li v-on:click="isActive='All'" v-bind:class="{ active: isActive=='All' }">All</li>
                                <li v-on:click="isActive='High'" v-bind:class="{ active: isActive=='High' }" >Online</li>
                                <li v-on:click="isActive='Medium'" v-bind:class="{ active: isActive=='Medium' }" >Offline</li> 
                            </ul>
                        </div>
                        <div class="task-filters-right">
                            <ul>
                                <li>
                                    <label>MEETING</label>
                                    <multiselect
                                        v-model="categorySelected"
                                        :show-labels="false" 
                                        :options="categoryOptions">
                                    </multiselect>
                                </li>
                                <li>
                                    <label>LOCATION</label> 
                                    <multiselect
                                        v-model="subCategorySelected" 
                                        :show-labels="false" 
                                        :options="subCategoryOptions">
                                    </multiselect>
                                </li>
                                <li>
                                    <label>TIME RANGE</label> 
                                    <div class="date_range">
                                        <dateRange></dateRange>                                 
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="task-list high">
                        <ul>
                            <li class="category">
                                <div class="task-item">
                                    <label>CATEGORY</label>
                                    <div class="task-content"><span>administration</span></div>
                                </div>
                                <div class="task-item">
                                    <label>DATE/TIME</label>
                                    <div class="task-content date"><p>21st Nov '20 </p><p>11:00 am - 1:00 pm</p></div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Priority</label>
                                    <div class="task-content">
                                        <p>High</p>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>Estimated time</label>
                                    <div class="task-content">
                                        <p>5 Mins</p>
                                    </div>
                                </div>
                            </li>
                            <li class="subject">
                                <div class="task-item">
                                    <label>SUBJECT</label>
                                    <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                                </div>
                                <div class="task-item">
                                    <label>DESCRIPTION</label>
                                    <div class="task-content">
                                        <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                                    </div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Assigned by</label>
                                    <div class="task-content">
                                        <div class="profile-sec">
                                            <figure><img src="@/assets/images/user.png"></figure>
                                            <figcaption>Meenal Shah</figcaption>
                                        </div>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>ATTACHMENT</label>
                                    <div class="task-content">
                                        <div class="attachments">
                                            <small>4</small>
                                        </div>
                                    </div>
                                </div>
                            </li>  
                            <li class="task-actions alert_actions">
                                <div class="task-item">
                                    <label>Action</label>
                                    <div class="task-content">
                                        <p>START</p>
                                        <p>Archive</p>
                                        <p>View Details</p>
                                    </div> 
                                </div> 
                            </li>
                        </ul>
                    </div>
                    <div class="task-list low">
                        <ul>
                            <li class="category">
                                <div class="task-item">
                                    <label>CATEGORY</label>
                                    <div class="task-content"><span>administration</span></div>
                                </div>
                                <div class="task-item">
                                    <label>DATE/TIME</label>
                                    <div class="task-content date"><p>21st Nov '20 </p><p>11:00 am - 1:00 pm</p></div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Priority</label>
                                    <div class="task-content">
                                        <p>Low</p>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>Estimated time</label>
                                    <div class="task-content">
                                        <p>5 Mins</p>
                                    </div>
                                </div>
                            </li>
                            <li class="subject">
                                <div class="task-item">
                                    <label>SUBJECT</label>
                                    <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                                </div>
                                <div class="task-item">
                                    <label>DESCRIPTION</label>
                                    <div class="task-content">
                                        <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                                    </div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Assigned by</label>
                                    <div class="task-content">
                                        <div class="profile-sec">
                                            <figure><img src="@/assets/images/user.png"></figure>
                                            <figcaption>Meenal Shah</figcaption>
                                        </div>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>ATTACHMENT</label>
                                    <div class="task-content">
                                        <div class="attachments">
                                            <small>4</small>
                                        </div>
                                    </div>
                                </div>
                            </li>  
                            <li class="task-actions alert_actions">
                                <div class="task-item">
                                    <label>Action</label>
                                    <div class="task-content">
                                        <p>START</p>
                                        <p>Archive</p>
                                        <p>View Details</p>
                                    </div> 
                                </div> 
                            </li>
                        </ul>
                    </div>
                    <div class="task-list medium">
                        <ul>
                            <li class="category">
                                <div class="task-item">
                                    <label>CATEGORY</label>
                                    <div class="task-content"><span>administration</span></div>
                                </div>
                                <div class="task-item">
                                    <label>DATE/TIME</label>
                                    <div class="task-content date"><p>21st Nov '20 </p><p>11:00 am - 1:00 pm</p></div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Priority</label>
                                    <div class="task-content">
                                        <p>Medium</p>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>Estimated time</label>
                                    <div class="task-content">
                                        <p>5 Mins</p>
                                    </div>
                                </div>
                            </li>
                            <li class="subject">
                                <div class="task-item">
                                    <label>SUBJECT</label>
                                    <div class="task-content"><strong>Collaboration - Yoga</strong></div>
                                </div>
                                <div class="task-item">
                                    <label>DESCRIPTION</label>
                                    <div class="task-content">
                                        <div class="description"><p>Second draft of the proposed Kanha budget is ready for review ...</p></div>
                                    </div>
                                </div>
                            </li>
                            <li class="owner">
                                <div class="task-item">
                                    <label>Assigned by</label>
                                    <div class="task-content">
                                        <div class="profile-sec">
                                            <figure><img src="@/assets/images/user.png"></figure>
                                            <figcaption>Meenal Shah</figcaption>
                                        </div>
                                    </div>
                                </div>
                                <div class="task-item">
                                    <label>ATTACHMENT</label>
                                    <div class="task-content">
                                        <div class="attachments">
                                            <small>4</small>
                                        </div>
                                    </div>
                                </div>
                            </li>  
                            <li class="task-actions alert_actions">
                                <div class="task-item">
                                    <label>Action</label>
                                    <div class="task-content">
                                        <p>START</p>
                                        <p>Archive</p>
                                        <p>View Details</p>
                                    </div> 
                                </div> 
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="dashborad_cnt performance_cnt" v-bind:class="{ 'open': isCurrent=='Performance' }">
                    <div class="tasks-header">
                        <div class="task-filters-left">
                            
                        </div>
                        <div class="task-filters-right">
                            <ul>                         
                                <li>
                                    <label>TIME RANGE</label> 
                                    <div class="date_range">
                                        <dateRange></dateRange>                                 
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-6">
                            <div class="row">
                                <div class="col col-xl-6 col-lg-12">
                                    <taskGraph></taskGraph>
                                </div>
                                <div class="col col-xl-6 col-lg-12">
                                    <taskTracker></taskTracker>
                                </div>
                            </div>
                        </div>
                        <div class="col col-6">
                            <timeTracker></timeTracker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12">
                            <assignedTaskTracker></assignedTaskTracker>
                        </div>
                    </div>
                </div>
            </div>
        </section>


</div>
</template>

<script>
import Multiselect from "vue-multiselect-inv";
import dateRange from '@/components/dateRange.vue'
import taskGraph from '@/views/dashboard/taskGraph.vue'
import timeTracker from '@/views/dashboard/timeTracker.vue'
import taskTracker from '@/views/dashboard/taskTracker.vue'
import assignedTaskTracker from '@/views/dashboard/assignedTaskTracker.vue'




//import Popper from 'vue-popperjs';
//import 'vue-popperjs/dist/vue-popper.css';
  export default {
    components: {
        Multiselect,
        dateRange,
        taskGraph,
        timeTracker,
        taskTracker,
        assignedTaskTracker
     //   Popper
    },
    data() {
      return {
        range: {
            start: new Date('start'),
            end: new Date('end'),
        },
        isActive: 'All',
        isCurrent:'Alerts',
        selected: null,
        categorySelected: 'All',
        subCategorySelected: 'All',
        TimeSelected: 'All',
        filteroptions: [
          { value: null, text: 'All' },
          { value: 'a', text: 'Option 1' },
          { value: 'b', text: 'Option 2' },
          { value: 'c', text: 'Option 3' },
          { value: 'd', text: 'Option 4',}
        ],
        options: ['list', 'of', 'options'],
        categoryOptions: ['All ', 'Publication & Media', 'Project', 'Administration' ,'People Connect'],
        subCategoryOptions: ['All ', 'Book', 'Interview', 'Vedio'],
        TimeOptions: ['All ', 'Time 1', 'Time 2', 'Time 3'],
      }
    },
     methods: {
      showModal() {
        this.$refs['my-modal'].show() 
      },
      hideModal() {
        this.$refs['my-modal'].hide() 
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn') 
      },
     




      
    },
  }
</script>
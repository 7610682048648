<template>
<div class="widget">
    <h6>Task Tracker</h6>
    <div class="widger_body">
      <div class="tasktracker_graph">
        <div class="graph_cnt">
          <ul>
            <li>
              <label>Publication &amp; Media</label>
              <div class="graph_elements">
                <p style="width:25%; background:#009D9A"></p>
                <p style="width:50%; background:#FFA726"></p>
                <p style="width:25%; background:#BC5090"></p>
              </div>
            </li>
            <li>
              <label>Projects</label>
              <div class="graph_elements">
                <p style="width:30%; background:#009D9A"></p>
                <p style="width:30%; background:#FFA726"></p>
                <p style="width:40%; background:#BC5090"></p>
              </div>
            </li>
            <li>
              <label>Administration</label>
              <div class="graph_elements">
                <p style="width:25%; background:#009D9A"></p>
                <p style="width:30%; background:#FFA726"></p>
                <p style="width:45%; background:#BC5090"></p>
              </div>
            </li>
            <li>
              <label>People Connect</label>
              <div class="graph_elements">
                <p style="width:40%; background:#009D9A"></p>
                <p style="width:20%; background:#FFA726"></p>
                <p style="width:40%; background:#BC5090"></p>
              </div>
            </li>
          </ul>
        </div>
        <ul class="legendlist">
          <li class="filed">
            <p>Completed</p>
          </li>
          <li class="nextdate">
            <p>Pending</p>
          </li>
          <li class="overdue">
            <p>Over Due</p>
          </li>
        </ul>
      </div>
    </div>
    
</div>
</template>

<script>
 
  export default {
    components: {
        
    },
    data() {
      return {
         
 
      }
    },
    mounted() {
       
    },

     methods: {
       
    }  
 
  }
</script>
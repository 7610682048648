<template>
<div class="widget">
    <h6>Time Tracker</h6>
    <div class="widger_body">
      <div class="timetracker_graph">
        <div class="chartdiv" id="trackgraph"></div>
        <div class="timetracker_details">
          <div class="timetracker_list online">
            <label>Online 25% </label>
            <ul>
              <li>
                <p>15% ( 75 Hrs )</p>
                <span>Internal Meeting</span>  
              </li>
              <li>
                <p>10% ( 45 Hrs )</p>
                <span>External Meeting</span>  
              </li>
            </ul>
          </div>
          <div class="timetracker_list offline">
            <label>Offline 75% </label>
            <ul>
              <li>
                <p>55% ( 110 Hrs )</p>
                <span>Internal Meeting</span>  
              </li>
                <li>
                  <p>20% ( 90 Hrs )</p>
                  <span>External Meeting</span>  
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
</div>
</template>

<script>
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4charts from "@amcharts/amcharts4/charts";
 // import am4themes_animated from "@amcharts/amcharts4/themes/animated";
  export default {
    components: {
        
    },
    data() {
      return {
         
 
      }
    },
    mounted() {
      this.initChart();
    },

     methods: {
      initChart() {
        // Create chart instance
var chart = am4core.create("trackgraph", am4charts.PieChart);

// Add data
chart.data = [{
  "status": "Online",
  "count": 25,
}, {
  "status": "Offline",
  "count": 75
}];

// Add and configure Series
var pieSeries = chart.series.push(new am4charts.PieSeries());

            var colorSet = new am4core.ColorSet();
            colorSet.list = ["#FFA726", "#714FFF"].map(function (color) {
                return new am4core.color(color);
            });


pieSeries.dataFields.value = "count";
pieSeries.dataFields.category = "status";
            pieSeries.colors = colorSet;

// Let's cut a hole in our Pie chart the size of 40% the radius
chart.innerRadius = am4core.percent(50);

// Put a thick white border around each Slice
pieSeries.slices.template.stroke = am4core.color("#4a2abb");
pieSeries.slices.template.strokeWidth = 0;
pieSeries.slices.template.strokeOpacity = 1;

// Disable ticks and labels
pieSeries.labels.template.disabled = true;
pieSeries.ticks.template.disabled = true;


      }
    }  
 
  }
</script>